/*Generic*/
body{
  font-family: Poppins;
  font-size: 14px;
  color: #33475b;
}
.container{
  margin:auto;
  max-width:1440px;
  clear:both
}
.whiteBg{
  background-color: #ffffff;
}
.primaryBg{
  background-color: #f8fffe;
 }
.secondaryBg{
  background-color: #01bfb5;  
}
.greenBg{
  background-image: linear-gradient(to left, #01bfb5, #00bda5);
}
.blueBg {
  background-image: linear-gradient(to left, #0ca7cf, #01a4bf);
}
.orangeBg{
  background-color:#ff9859;
}
.whiteColor{
  color:#ffffff;
}
.primaryColor{
  color:#01a4bf;
}
.secondaryColor{
  color: #01bfb5;
}
.horizontal{
  display: flex;
  flex-direction: row;
}
.vertical{
  display: flex;
  flex-direction: column;
}
button{
  width: 200px;
  height: 40px;
  border-radius: 24px;
  font-weight: 500;
  text-align: center;
  margin:8px 0px;
  border:none;
  cursor:pointer;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.primaryButton{
  background-color: #ff7a59;
  color: #ffffff;
}
.secondaryButton{
  background-color: #ffffff;
  border: solid 2px #ff7a59;
  color: #ff7a59;
}
.blueButton{
  background-color: #33475b;
  border: solid 2px #33475b;
  color: #ffffff;
}
.centerText{
  text-align:center;
}
.center{
  justify-content: center;
}
.centerMargin{
  margin:auto;
  clear:both;
}
.margin2{
  margin:2%;
}
.margin8{
  margin:8%;
}
.margin16{
  margin:16%;
}
.marginCenter8{
  margin: 2% 8%;
}
.marginCenter16{
  margin: 2% 16%;
}
.marginCenter24{
  margin: 2% 24%;
}
.padding2{
  padding:2%;
}
.paddingCenter16{
  padding: 2% 16%;
}
.anythingCenter{
  display: block;
  margin:8px auto;
}
.weight600{
  font-weight:600;
}
.floatRight{
  float: right;
}
/*Start of Media Queries*/
@media only screen and (max-width: 768px) {
  .container{
    min-width:320px;
    overflow:hidden;
  }
  .photoImage{
    width:100%;
    object-fit: contain;
  }
  .horizontal{
    flex-direction: column; 
  }
  .rectangleCenter{
    padding:0px 2%;
  }
  .twoBlockImage {
    margin-left:2%; 
  }
  .marginCenter8{
    margin: 2% 2%;
  }
  .marginCenter24{
    margin: 2% 2%;
  }
  .paddingCenter16{
    padding: 2% 2%;
  }
  .padding2{
    padding:0%;
  }
  }  
/*End of Media Queries*/
/*End of Generic*/