body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Generic*/
body{
  font-family: Poppins;
  font-size: 14px;
  color: #33475b;
}
.container{
  margin:auto;
  max-width:1440px;
  clear:both
}
.whiteBg{
  background-color: #ffffff;
}
.primaryBg{
  background-color: #f8fffe;
 }
.secondaryBg{
  background-color: #01bfb5;  
}
.greenBg{
  background-image: -webkit-gradient(linear, right top, left top, from(#01bfb5), to(#00bda5));
  background-image: linear-gradient(to left, #01bfb5, #00bda5);
}
.blueBg {
  background-image: -webkit-gradient(linear, right top, left top, from(#0ca7cf), to(#01a4bf));
  background-image: linear-gradient(to left, #0ca7cf, #01a4bf);
}
.orangeBg{
  background-color:#ff9859;
}
.whiteColor{
  color:#ffffff;
}
.primaryColor{
  color:#01a4bf;
}
.secondaryColor{
  color: #01bfb5;
}
.horizontal{
  display: flex;
  flex-direction: row;
}
.vertical{
  display: flex;
  flex-direction: column;
}
button{
  width: 200px;
  height: 40px;
  border-radius: 24px;
  font-weight: 500;
  text-align: center;
  margin:8px 0px;
  border:none;
  cursor:pointer;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.primaryButton{
  background-color: #ff7a59;
  color: #ffffff;
}
.secondaryButton{
  background-color: #ffffff;
  border: solid 2px #ff7a59;
  color: #ff7a59;
}
.blueButton{
  background-color: #33475b;
  border: solid 2px #33475b;
  color: #ffffff;
}
.centerText{
  text-align:center;
}
.center{
  justify-content: center;
}
.centerMargin{
  margin:auto;
  clear:both;
}
.margin2{
  margin:2%;
}
.margin8{
  margin:8%;
}
.margin16{
  margin:16%;
}
.marginCenter8{
  margin: 2% 8%;
}
.marginCenter16{
  margin: 2% 16%;
}
.marginCenter24{
  margin: 2% 24%;
}
.padding2{
  padding:2%;
}
.paddingCenter16{
  padding: 2% 16%;
}
.anythingCenter{
  display: block;
  margin:8px auto;
}
.weight600{
  font-weight:600;
}
.floatRight{
  float: right;
}
/*Start of Media Queries*/
@media only screen and (max-width: 768px) {
  .container{
    min-width:320px;
    overflow:hidden;
  }
  .photoImage{
    width:100%;
    object-fit: contain;
  }
  .horizontal{
    flex-direction: column; 
  }
  .rectangleCenter{
    padding:0px 2%;
  }
  .twoBlockImage {
    margin-left:2%; 
  }
  .marginCenter8{
    margin: 2% 2%;
  }
  .marginCenter24{
    margin: 2% 2%;
  }
  .paddingCenter16{
    padding: 2% 2%;
  }
  .padding2{
    padding:0%;
  }
  }  
/*End of Media Queries*/
/*End of Generic*/
.footer{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #33475b;
    cursor: pointer;
    padding-top: 21px; 
}
.container{
    margin:auto;
    max-width:1440px;
    clear:both
  }


.footer-logo{
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-right: 19.3%;
    font-family: Montserrat;
    font-size: 24px;
    padding-top: 21px;
    padding-bottom: 21px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.ve{
    color: #ff7a59;
}

.footer-page-route{
    display: flex;
    flex-direction: row;
    padding-top: 21px;
    padding-bottom : 21px;
}

.footer-page-link{
    font-family: Poppins;
    opacity: 0.75;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #d8d8d8;
    align-content: center;
    margin-left: 23px;
    margin-right: 23px;
    margin-top: 5px;
}

.foo-active{
    color: #ffffff;
    opacity: 1;
    border-bottom: 2px solid #ffffff;
}

.foo-contact-number{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #00bda5;
}

.footer-register-button{
    width: 11.8%;
    height: 39px;
    border-radius: 26.5px;
    background-color: #ff7a59;
    margin-left: 163px;
    margin-top: 16px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
}

.copyright{
    opacity: 0.4;
    font-family: Poppins;
    font-size: 12px;
    line-height: 2;
    color: #ffffff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 600px) {
    .footer{
        flex-direction: column;
    }

    .footer-page-route{
        flex-direction: column;
    }

    .footer-logo{
        margin-left: auto;
        margin-right: auto;
    }
    .foo-active{
        border-bottom: none;
    }
}

.logo{
    font-size: 24px;
    cursor:pointer;
    padding:0px 20px;
}
.nav{
    padding:20px;
    cursor: pointer;
    float: left;
}
.active{
    color: #01a4bf;
    border-bottom: 2px solid #01a4bf;
}
@media only screen and (max-width: 768px) {
    .active{
        border: none;
    }
    .ctaWeb{
        display:none;
    }
    .logo{
        margin:auto;  
    }
}
/*Rectangle*/
.rectangle{
  width: 280px;
  padding:8px;
  margin: 2% auto;
  border-radius: 10px;
  /* box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.04); */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.whiteRectangle{
  background-color: #ffffff;
}
.greenRectangle{
  background-color: #039a87;
}
.rectangleCenter{
  padding:0px 16%;
}
.whiteBox{
  padding:16px;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.04);
  background-color:#ffffff;
}
/*Rectangle*/
/*Start of 2blocks*/
.twoBlockImage {
  width:300px;
  object-fit: contain;
  margin-left:12%; 
}
/*End of 2blocks*/
/*Start of Rectangle*/
.small-box{
  width: 60px;
  height: 24px;
  border-radius: 15px;
  background-color: #f8fffe;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
}
/*End of Rectangle*/
/*Start of Ballon*/
.ballon {

  height :  96px; 
  width : 230px;                             
  background-position-y : 90%; 
  background-repeat: no-repeat;
}
.teve-ballon{
padding-left: 40%; padding-right: 40%;
}
/*End of Ballon*/
/*Start of CTA*/
.Rectangle-Copy {
  width: 280px;
  height: 42px;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
  background-color: #f1fcff;
  border:none;
  text-align:center;
 }
.Rectangle-Copy-2 {
  width: 147px;
  height: 45px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  background-color: #0a5b71;
  border-color:#0a5b71;
  border:none;
  cursor:pointer;
}
/*End of CTA*/
/*Start of Media Queries*/
@media only screen and (max-width: 768px) {
.container{
  min-width:320px;
  overflow:hidden;
}
.photoImage{
  width:100%;
  object-fit: contain;
}
.horizontal{
  flex-direction: column; 
}
.rectangleCenter{
  padding:0px 2%;
}
.twoBlockImage {
  margin-left:2%; 
}
.marginCenter8{
  margin: 2% 2%;
}
.marginCenter24{
  margin: 2% 2%;
}
.paddingCenter16{
  padding: 2% 2%;
}
.padding2{
  padding:0%;
}
}

/*Start of */
.Mask1 {
  width: 100%;
  background-image: linear-gradient(112deg, #01a4bf, #35cae2);
  display: flex;
  flex-direction: row;
}
.Mask2 {
  width: 800px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-top:5%;
  margin-left:14.5%;
  margin-right:26.9%;
 }
.Mask3{
  display: flex;
  flex-direction:column;
  margin-top:5%; 
  margin-left:10%;
  margin-right:10%; 
}
.box{
  width:320px;
}
.smallBox {
  width: 320px;
  height: 120px;
  border-radius: 8px;
  background-color: rgb(255,255,255,0.15);
  margin:8px;;
}
.features-banner {
  width: 577px;
  height: 320px;
  object-fit: contain;
  margin:20% 2%;
}
.section1 {
  width: 361px;
  height:   361px;
  object-fit: contain;
  margin:10%;
  right:9%;
  top:115%;
  display:none;
}
.expand-arrow {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-left:80%;
  margin-right:80%;
  margin-top:5%;  
}
/*End of */ 
@media only screen and (max-width: 1024px) {
.Sell-more-produc{
  margin-left:42%;
  margin-right:42%;
}
.rrr1{
  margin-left:42%;
  margin-right:42%;
}
.bannerBox{
  margin-top:10%;
}
.features-banner{
  width:400px;
  height: 310px;
  margin-top:35%;
}
.Mask3 {
  width:650px;
}
}




.bg {
  width: 100%;
  height: 240px;
  object-fit: contain;
}
.box{
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding:32px 0px;
  margin:-80px 8px auto 8px;
  max-width:304px;
 }
 .boxL {
  width: 449px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-left:32%;
  margin-right:32%;
  margin-top:10%;
}
.boxS {
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin:4% 12%;
}
.line {
  width: 370px;
  height: 44px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.03);
  margin-left:9.1%;
  margin-right:9.1%;
  margin-top:2%;
}
.placeholder {
  color: rgba(51, 71, 91, 0.25); 
}
@media only screen and (max-width: 768px) {
  .box{
    margin:16px auto 0px auto;
  }
  .boxMobile{
    margin:-80px auto 0px auto;
  }
  .boxS {
    margin:4% 2%;
  }
}
/*End of */
.header{
    max-width:1080px;
}
.logoR{
    font-size: 24px;
    cursor:pointer;
    padding:20px 20px;
}
.boxM{
width: 40%;
height: 50%;
object-fit: contain;
border-radius: 8px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
background-color: #ffffff;
margin-left:auto;
margin-right:auto;
margin-top:5%;
padding:16px;
}
.input {
    min-width: 304px;
    min-height: 44px;
    color: "#000000";
    margin: 8px 32px;
    border: solid 1px rgba(0, 0, 0, 0.04);
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 5px;
  }
.chumma{
    padding-bottom:400px;  
}
@media only screen and (max-width: 768px) {
    .header{
        margin:auto;
        text-align:center;
    }
    .boxM{
        width:96%;
    }
    .input{
        margin:2px 2px;
        min-width:280px;
        width:280px;
    }
}
