.footer{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #33475b;
    cursor: pointer;
    padding-top: 21px; 
}
.container{
    margin:auto;
    max-width:1440px;
    clear:both
  }


.footer-logo{
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-right: 19.3%;
    font-family: Montserrat;
    font-size: 24px;
    padding-top: 21px;
    padding-bottom: 21px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.ve{
    color: #ff7a59;
}

.footer-page-route{
    display: flex;
    flex-direction: row;
    padding-top: 21px;
    padding-bottom : 21px;
}

.footer-page-link{
    font-family: Poppins;
    opacity: 0.75;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #d8d8d8;
    align-content: center;
    margin-left: 23px;
    margin-right: 23px;
    margin-top: 5px;
}

.foo-active{
    color: #ffffff;
    opacity: 1;
    border-bottom: 2px solid #ffffff;
}

.foo-contact-number{
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #00bda5;
}

.footer-register-button{
    width: 11.8%;
    height: 39px;
    border-radius: 26.5px;
    background-color: #ff7a59;
    margin-left: 163px;
    margin-top: 16px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
}

.copyright{
    opacity: 0.4;
    font-family: Poppins;
    font-size: 12px;
    line-height: 2;
    color: #ffffff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 600px) {
    .footer{
        flex-direction: column;
    }

    .footer-page-route{
        flex-direction: column;
    }

    .footer-logo{
        margin-left: auto;
        margin-right: auto;
    }
    .foo-active{
        border-bottom: none;
    }
}
