/*Start of */
.Mask1 {
  width: 100%;
  background-image: linear-gradient(112deg, #01a4bf, #35cae2);
  display: flex;
  flex-direction: row;
}
.Mask2 {
  width: 800px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-top:5%;
  margin-left:14.5%;
  margin-right:26.9%;
 }
.Mask3{
  display: flex;
  flex-direction:column;
  margin-top:5%; 
  margin-left:10%;
  margin-right:10%; 
}
.box{
  width:320px;
}
.smallBox {
  width: 320px;
  height: 120px;
  border-radius: 8px;
  background-color: rgb(255,255,255,0.15);
  margin:8px;;
}
.features-banner {
  width: 577px;
  height: 320px;
  object-fit: contain;
  margin:20% 2%;
}
.section1 {
  width: 361px;
  height:   361px;
  object-fit: contain;
  margin:10%;
  right:9%;
  top:115%;
  display:none;
}
.expand-arrow {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-left:80%;
  margin-right:80%;
  margin-top:5%;  
}
/*End of */ 
@media only screen and (max-width: 1024px) {
.Sell-more-produc{
  margin-left:42%;
  margin-right:42%;
}
.rrr1{
  margin-left:42%;
  margin-right:42%;
}
.bannerBox{
  margin-top:10%;
}
.features-banner{
  width:400px;
  height: 310px;
  margin-top:35%;
}
.Mask3 {
  width:650px;
}
}



