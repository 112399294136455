/*Rectangle*/
.rectangle{
  width: 280px;
  padding:8px;
  margin: 2% auto;
  border-radius: 10px;
  /* box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.04); */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.whiteRectangle{
  background-color: #ffffff;
}
.greenRectangle{
  background-color: #039a87;
}
.rectangleCenter{
  padding:0px 16%;
}
.whiteBox{
  padding:16px;
  border-radius: 10px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.04);
  background-color:#ffffff;
}
/*Rectangle*/
/*Start of 2blocks*/
.twoBlockImage {
  width:300px;
  object-fit: contain;
  margin-left:12%; 
}
/*End of 2blocks*/
/*Start of Rectangle*/
.small-box{
  width: 60px;
  height: 24px;
  border-radius: 15px;
  background-color: #f8fffe;
  margin-left: auto;
  margin-right: auto;
  margin-top:-20px;
}
/*End of Rectangle*/
/*Start of Ballon*/
.ballon {

  height :  96px; 
  width : 230px;                             
  background-position-y : 90%; 
  background-repeat: no-repeat;
}
.teve-ballon{
padding-left: 40%; padding-right: 40%;
}
/*End of Ballon*/
/*Start of CTA*/
.Rectangle-Copy {
  width: 280px;
  height: 42px;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
  background-color: #f1fcff;
  border:none;
  text-align:center;
 }
.Rectangle-Copy-2 {
  width: 147px;
  height: 45px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  background-color: #0a5b71;
  border-color:#0a5b71;
  border:none;
  cursor:pointer;
}
/*End of CTA*/
/*Start of Media Queries*/
@media only screen and (max-width: 768px) {
.container{
  min-width:320px;
  overflow:hidden;
}
.photoImage{
  width:100%;
  object-fit: contain;
}
.horizontal{
  flex-direction: column; 
}
.rectangleCenter{
  padding:0px 2%;
}
.twoBlockImage {
  margin-left:2%; 
}
.marginCenter8{
  margin: 2% 2%;
}
.marginCenter24{
  margin: 2% 2%;
}
.paddingCenter16{
  padding: 2% 2%;
}
.padding2{
  padding:0%;
}
}
