.logo{
    font-size: 24px;
    cursor:pointer;
    padding:0px 20px;
}
.nav{
    padding:20px;
    cursor: pointer;
    float: left;
}
.active{
    color: #01a4bf;
    border-bottom: 2px solid #01a4bf;
}
@media only screen and (max-width: 768px) {
    .active{
        border: none;
    }
    .ctaWeb{
        display:none;
    }
    .logo{
        margin:auto;  
    }
}