.bg {
  width: 100%;
  height: 240px;
  object-fit: contain;
}
.box{
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding:32px 0px;
  margin:-80px 8px auto 8px;
  max-width:304px;
 }
 .boxL {
  width: 449px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-left:32%;
  margin-right:32%;
  margin-top:10%;
}
.boxS {
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin:4% 12%;
}
.line {
  width: 370px;
  height: 44px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.03);
  margin-left:9.1%;
  margin-right:9.1%;
  margin-top:2%;
}
.placeholder {
  color: rgba(51, 71, 91, 0.25); 
}
@media only screen and (max-width: 768px) {
  .box{
    margin:16px auto 0px auto;
  }
  .boxMobile{
    margin:-80px auto 0px auto;
  }
  .boxS {
    margin:4% 2%;
  }
}
/*End of */