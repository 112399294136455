.header{
    max-width:1080px;
}
.logoR{
    font-size: 24px;
    cursor:pointer;
    padding:20px 20px;
}
.boxM{
width: 40%;
height: 50%;
object-fit: contain;
border-radius: 8px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
background-color: #ffffff;
margin-left:auto;
margin-right:auto;
margin-top:5%;
padding:16px;
}
.input {
    min-width: 304px;
    min-height: 44px;
    color: "#000000";
    margin: 8px 32px;
    border: solid 1px rgba(0, 0, 0, 0.04);
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 5px;
  }
.chumma{
    padding-bottom:400px;  
}
@media only screen and (max-width: 768px) {
    .header{
        margin:auto;
        text-align:center;
    }
    .boxM{
        width:96%;
    }
    .input{
        margin:2px 2px;
        min-width:280px;
        width:280px;
    }
}